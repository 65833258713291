import React from "react";
import ViewRequestComponent from "../../../Components/Admin/view-request";

function ViewRequestPage() {
  return (
    <>
      <ViewRequestComponent />
    </>
  );
}

export default ViewRequestPage;
