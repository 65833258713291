import React from "react";
import { NavbarOne } from "../../Components/Owner/Navbar";
import EditTableComponent from "../../Components/Owner/edit-table";
function EditTablePage() {
  return (
    <div>
      <NavbarOne />
      <EditTableComponent />
    </div>
  );
}

export default EditTablePage;
