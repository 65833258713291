import React from "react";
import { NavbarOne } from "../../Components/Owner/Navbar";
import AddTableForm from "../../Components/Owner/add-table";
function AddTablePage() {
  return (
    <div>
      <NavbarOne />
      <AddTableForm />
    </div>
  );
}

export default AddTablePage;
