import React from "react";
import { NavbarOne } from "../../Components/Owner/Navbar";
import EditDishComponent from "../../Components/Owner/edit-dishe";
function EditDishPage() {
  return (
    <div>
      <NavbarOne />
      <EditDishComponent />
    </div>
  );
}

export default EditDishPage;
