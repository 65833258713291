import React from "react";
// import {NavbarOne} from '../../Components/Owner/Navbar'
// import AddRestaurantComponent from '../../Components/Owner/add-restaurant'
import AddRestaurantComponent from "../../Components/Owner/addres-sample";

function addrestaurantPage() {
  return (
    <div>
      {/* <NavbarOne/> */}
      <AddRestaurantComponent />
    </div>
  );
}

export default addrestaurantPage;
