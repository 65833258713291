import React from 'react'
import RestorantCardComponent from '../../Cards/RestaurantCard'
function Restaurent() {
  return (
    <div>
<RestorantCardComponent/>
    </div>
  )
}

export default Restaurent