import React from 'react'
import ViewOrder from '../../../Components/Owner/UserOrderDetails/ViewDetails'
function ViewOrders() {
  return (
    <div>
        <ViewOrder/>
    </div>
  )
}

export default ViewOrders