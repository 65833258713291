import React from 'react'

function ViewDetails() {
  return (
    <div>
         view details
    </div>
  )
}

export default ViewDetails