import React from "react";
import { NavbarOne } from "../../Components/Owner/Navbar";

import AddDishComponent from "../../Components/Owner/add-dishe";
function AddDishPage() {
  return (
    <div>
      <NavbarOne />
      <AddDishComponent />
    </div>
  );
}

export default AddDishPage;
